<template>
  <div>
    <!-- 留言 -->
    <div class="messageBoard">
      <div class="messageBoardList">
        <p>留言列表：</p>
        <p v-for="(item, index) in dataList" :key="index">
          {{ item.userWords }}
        </p>
      </div>
      <div class="messageContent">
        <textarea
          v-model="form.message"
          class="messageBoardContent"
          placeholder="请输入留言内容"
        ></textarea>
        <div class="sendButton" @click="onSubmit">发送</div>
      </div>
    </div>
  </div>
</template>

<script>
import Viewheader from '../../components/viewheader/viewheader.vue';
import Footering from '../../components/foot/viewfooter.vue';
// 公用组件
import '../../assets/css/public.css';
import { getMessageList, messageToDhgate } from '@/api/aidata.js';
export default {
  name: 'viewheader',
  props: ['colorful', 'isHome'],
  data() {
    return {
      dataList: [],
      form: {
        message: '',
      },
    };
  },
  components: {
    Viewheader,
    Footering,
  },
  mounted() {
    this.getMessageList();
  },
  methods: {
    // 获取留言板数据
    getMessageList() {
      getMessageList().then((res) => {
        if (res.data.state === '0x0000') {
          this.dataList = res.data.data;
        }
      });
    },
    // 提交留言
    onSubmit() {
      messageToDhgate({ userWords: this.form.message }).then(({ data }) => {
        if (data.state === '0x0000') {
          this.$message({
            message: '留言成功',
            type: 'success',
          });
          this.getMessageList();
          this.form.message = '';
          setTimeout(() => {
            this.$router.push({
              path: '/',
            });
          }, 2000);
        } else if (data.state == '0x0008') {
          this.$message.error(data.message);
          this.$refs.navheader.logOn();
        } else {
          this.$message.error(data.message);
        }
      });
    },
  },
};
</script>

<style scoped>
.messageBoard {
  width: 680px;
  background-color: #f9f8fc;
  /* margin:30px auto; */
  border-radius: 6px 6px 6px 6px;
  padding-top: 20px;
  padding-bottom: 50px;
}
.messageBoardList {
  width: 600px;
  height: 200px;
  background-color: #fff;
  margin-left: 40px;
  margin-top: 20px;
  border-radius: 6px 6px 6px 6px;
  overflow-y: scroll;
  /* padding-left: ; */
}
.messageBoardList p {
  width: 96%;
  margin: 10px auto;
}
.messageBoardList p {
  font-size: 14px;
  font-family: PingFang-SC-Regular;
}
.messageContent {
  width: 600px;
  height: 300px;
  background-color: #fff;
  margin-left: 40px;
  margin-top: 20px;
  padding-top: 20px;
  border-radius: 6px 6px 6px 6px;
  position: relative;
}
.messageContent textarea {
  resize: none;
}
.sendButton {
  width: 122px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  background: #6567e1;
  border-radius: 5px;
  opacity: 1;
  color: #fff;
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}
/*滚动条样式*/
.messageBoardList::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
.messageBoardList::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2); /*设置滚动条颜色*/
}
.messageBoardList::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
  border-radius: 0;
  /* background:rgba(0,0,0,0.1); */
}
.messageBoardContent {
  width: 95%;
  height: 200px;
  display: block;
  margin: auto;
  font-size: 14px;
  outline: none;
  border: none;
  line-height: 28px;
}

/*滚动条样式*/
.messageBoardContent::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
.messageBoardContent::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2); /*设置滚动条颜色*/
}
.messageBoardContent::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
  border-radius: 0;
  /* background:rgba(0,0,0,0.1); */
}
</style>
